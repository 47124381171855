import React from 'react';
import '../App.css';

import client_1 from '../assets/images/logo-1-color.png';
import client_2 from '../assets/images/logo-2-color.png';
import client_3 from '../assets/images/logo-3-color.png';
import client_4 from '../assets/images/logo-4-color.png';
import client_5 from '../assets/images/logo-5-color.png';

import skill_1 from '../assets/images/icon-1.svg';
import skill_2 from '../assets/images/icon-2.svg';
import skill_3 from '../assets/images/icon-3.svg';
import skill_4 from '../assets/images/icon-4.svg';


class About extends React.Component {
    render() {
        return (
            <div>
              
              <header>
                <h2 class="h2 article-title">About me</h2>
              </header>

              <section class="about-text">
                <p>
                  <b>5th-year Engineering</b> student at <b>EFREI Paris school</b>, I'm eager to secure my first full time <b>Entry-level</b> position in <b>Data Science</b> or Data Engineering.
                  <br/>
                  With expertise in <b>DevOps</b> tools and experience in <b>MLOps</b>, I'm particularly interested in <b>ML Engineering</b> roles.
                </p>
              </section>


              <section class="service">

                <h3 class="h3 service-title">What i'm doing</h3>

                <ul class="service-list">

                  <li class="service-item">

                    <div class="service-icon-box">
                      <img src={skill_1} alt="design icon" width="40"/>
                    </div>

                    <div class="service-content-box">
                      <h4 class="h4 service-item-title">Advanced Analytics and Experimentation</h4>

                      <p class="service-item-text">
                        Machine learning, deep learning, and statistical analysis to develop models and conduct experiments for data-driven insights and predictions.
                      </p>
                    </div>

                  </li>

                  <li class="service-item">

                    <div class="service-icon-box">
                      <img src={skill_2} alt="Web development icon" width="40"/>
                    </div>

                    <div class="service-content-box">
                      <h4 class="h4 service-item-title">Deployment Orchestration and MLOps Management</h4>

                      <p class="service-item-text">
                        Expertise in deploying machine learning models efficiently in production environments, managing CI/CD pipelines, and ensuring model performance through MLOps practices.
                      </p>
                    </div>

                  </li>

                  <li class="service-item">

                    <div class="service-icon-box">
                      <img src={skill_3} alt="mobile app icon" width="40"/>
                    </div>

                    <div class="service-content-box">
                      <h4 class="h4 service-item-title">Pipeline Development and Database Management</h4>

                      <p class="service-item-text">
                        Ability to transform data into visually appealing insights and reports, facilitating informed decision-making and strategy development for businesses.
                      </p>
                    </div>

                  </li>

                  <li class="service-item">

                    <div class="service-icon-box">
                      <img src={skill_4} alt="camera icon" width="40"/>
                    </div>

                    <div class="service-content-box">
                      <h4 class="h4 service-item-title">Data Visualization, Reporting, and Business Insights</h4>

                      <p class="service-item-text">
                        Skills in designing and maintaining data processing pipelines, along with managing databases for efficient storage, retrieval, and management of data assets.
                      </p>
                    </div>

                  </li>

                </ul>

              </section>





              <section class="clients">

                <h3 class="h3 clients-title">They Trust Me</h3>

                <ul class="clients-list has-scrollbar">

                  <li class="clients-item">
                    <a href="/resume">
                      <img src={client_1} alt="client logo"/>
                    </a>
                  </li>

                  <li class="clients-item">
                    <a href="/resume">
                      <img src={client_2} alt="client logo"/>
                    </a>
                  </li>

                  <li class="clients-item">
                    <a href="/resume">
                      <img src={client_3} alt="client logo"/>
                    </a>
                  </li>

                  <li class="clients-item">
                    <a href="/resume">
                      <img src={client_4} alt="client logo"/>
                    </a>
                  </li>

                  <li class="clients-item">
                    <a href="/resume">
                      <img src={client_5} alt="client logo"/>
                    </a>
                  </li>

                </ul>

              </section>




            </div>
        );
    }
}

export default About;