import React from 'react';
import { IonIcon } from '@ionic/react';
import { business, school } from 'ionicons/icons';

const ResumeComponent = ({ componentList, sectionTitle }) => {
  return (
    <div>
      <section className="timeline">
        <div className="title-wrapper">
          <div className="icon-box">
            <IonIcon icon={sectionTitle === 'Education' ? school : business} />
          </div>
          <h3 className="h3">{sectionTitle}</h3>
        </div>
        <ol className="timeline-list">
          {componentList.map((component, index) => (
            <li className="timeline-item" key={index}>
              <h4 className="h4 timeline-item-title">{component.activityTitle}</h4>
              <span className="organization">{component.activityOrganisation}</span>
              <span>{component.activityTimeFrame}</span>
              <p className="timeline-text">{component.activityDescription}</p>
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
};

export default ResumeComponent;
