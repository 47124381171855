import React from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";

const Navbar  = () => {

    
    const location = useLocation();

    // Function to determine if the current path matches the link path
    const isActive = (linkPath) => {
        return location.pathname.includes(linkPath);
    };

    

        return (
            <div>

                <nav class="navbar navbox">

                    <ul class="navbar-list">

                        <li class="navbar-item">
                            <Link class={`navbar-link ${isActive('/about') ? 'active' : ''}`} data-nav-link to="/about">About</Link>
                        </li>

                        <li class="navbar-item">
                            <Link class={`navbar-link ${isActive('/resume') ? 'active' : ''}`} data-nav-link to="/resume">Resume</Link>
                        </li>
{/* 
                        <li class="navbar-item">
                            <Link class={`navbar-link ${isActive('/portfolio') ? 'active' : ''}`} data-nav-link to="/portfolio">Portfolio</Link>
                        </li> */}

                        {/* <li class="navbar-item">
                            <Link class={`navbar-link ${isActive('/blog') ? 'active' : ''}`} data-nav-link to="/blog">Blog</Link>
                        </li> */}

                        <li class="navbar-item">
                            <Link class={`navbar-link ${isActive('/contact') ? 'active' : ''}`} data-nav-link to="/contact">Contact</Link>
                        </li>

                    </ul>

                </nav>

                <Outlet />

            </div>
        );
    }


export default Navbar;