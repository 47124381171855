import React from 'react';
import ReactDOM from 'react-dom/client';
import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
setupIonicReact();
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

