import React from 'react';
import '../App.css';
import { Navigate, Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

const Project = ({ projectList, modalId }) => {
  const project = projectList.find(project => project.id === parseInt(modalId));
  if (!project) {
    return <Navigate to="/NotFound" />
  }

  return (
    <div className='projectPage'>
      <header>
        <Link to="/Portfolio" className="back-btn">
          <IonIcon icon={chevronBack} />
          Back
        </Link>
      </header>

      <h2 className="h2 article-title">{project.title}</h2>
      
      <section className="clients">
        <ul className="img-list has-scrollbar">
          {/* {project.img.map((image, index) => (
            <li key={index}>
              <img src={image} alt={`project illustration ${index + 1}`} />
            </li>
          ))} */}
        </ul>
      </section>

      <h4 className="timeline-text">About</h4>
      <p className="timeline-text">{project.about}</p>
      <br />

      <h4 className="timeline-text">Links</h4>
      <div className='linkBox'>
        {project.links.map((link, index) => (
          <a href={link.url} target="_blank" rel="noopener noreferrer" key={index}>
            {link.title}
          </a>
        ))}
      </div>
      <br />
      
      <h4 className="timeline-text">Objective</h4>
      <p className="timeline-text">{project.objective}</p>
      <br />
      
      <h4 className="timeline-text">Tools</h4>
      <p className="timeline-text">{project.tools}</p>
      <br />

      <h4 className="timeline-text">Summary</h4>
      <p className="timeline-text">{project.summary}</p>
      <br />
      
      <h4 className="timeline-text">Description</h4>
      <p className="timeline-text">{project.description}</p>
    </div>
  );
};

export default Project;
