import React from 'react';
import NotFoundgif from '../assets/images/NotFound.gif';

class NotFound extends React.Component {
    render() {
        return (
            <div>
                <img src={NotFoundgif} alt="NotFoundgif" style={{width: '100%', borderRadius: '20px', padding: '5px'}} />

                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <a href="/" >
                        <button style={{display: 'block', textAlign: 'center', fontSize: '20px', borderRadius: '5px', marginTop: '20px', padding: '10px', backgroundColor: 'hsla(240, 95%, 15%, 0.464)', color: '#FFFFFF'}}>
                            Back Home
                        </button>
                    </a>
                </div>
            </div>
        );
    }
}

export default NotFound;