import bg_video from './assets/images/bg.gif';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Sidebar from './components/Sidebar.js';
import Navbar from './components/Navbar.js';
import About from './components/About.js';
import Resume from './components/Resume.js';
import Portfolio from './components/Portfolio.js';
import Contact from './components/Contact.js';
import Project from './components/Project.js';
import NotFound from './components/NotFound.js';
import HelloWorld from './components/HelloWorld.js';

import data from './assets/content/content.json';

import project_1 from './assets/images/project-1.png';
import project_2 from './assets/images/project-2.png';
import project_3 from './assets/images/project-3.png';

function App() {
  
  const PortfolioWithModal = () => {
    // Extract modalId from the URL if location object is defined
    const location = useLocation();
    const modalId = location ? location.pathname.replace('/project/', '') : null;
    return <Project projectList={data.portfolio.projects} modalId={modalId} />;
  };
  
  return (
    <div className="App">
      
      <div>
        <Toaster position="bottom-right"/>
      </div>

      
    <body>
      <img src={bg_video} class='background_video' alt="background_video"/>


      <main>  

        <aside>
          <Sidebar />
        </aside>


        <div class="main-content">
          <article class="about  active" data-page="about">

          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navbar />}>
                <Route path="/" element={<Navigate to="about" replace={true} />} />
                <Route path="about" element={<About />} /> 
                <Route path="resume" element={<Resume resumeData={data.resume}/>} />
                <Route path="portfolio" element={<Portfolio projectList={data.portfolio.projects} />} />
                <Route path="contact" element={<Contact />} />
                <Route path="project/*" element={<PortfolioWithModal />} />
                <Route path="NotFound" element={<NotFound />} />
                <Route path="helloworld" element={<HelloWorld />} />
                <Route path="*" element={<Navigate to="NotFound" replace={true} />} />
              </Route>
            </Routes>
          </BrowserRouter>

          </article>


        <article class="blog" data-page="blog">

          <header>
            <h2 class="h2 article-title">Blog</h2>
          </header>

          <section class="blog-posts">

            <ul class="blog-posts-list">

              <li class="blog-post-item">
                <a href="#">

                  <figure class="blog-banner-box">
                    <img src="./assets/images/blog-1.png" alt="Design conferences in 2022" loading="lazy"/>
                  </figure>

                  <div class="blog-content">

                    <div class="blog-meta">
                      <p class="blog-category">Design</p>

                      <span class="dot"></span>

                      <time datetime="2022-02-23">Fab 23, 2022</time>
                    </div>

                    <h3 class="h3 blog-item-title">Design conferences in 2022</h3>

                    <p class="blog-text">
                      Veritatis et quasi architecto beatae vitae dicta sunt, explicabo.
                    </p>

                  </div>

                </a>
              </li>

              <li class="blog-post-item">
                <a href="#">

                  <figure class="blog-banner-box">
                    <img src="./assets/images/blog-2.png" alt="Best fonts every designer" loading="lazy"/>
                  </figure>

                  <div class="blog-content">

                    <div class="blog-meta">
                      <p class="blog-category">Design</p>

                      <span class="dot"></span>

                      <time datetime="2022-02-23">Fab 23, 2022</time>
                    </div>

                    <h3 class="h3 blog-item-title">Best fonts every designer</h3>

                    <p class="blog-text">
                      Sed ut perspiciatis, nam libero tempore, cum soluta nobis est eligendi.
                    </p>

                  </div>

                </a>
              </li>

              <li class="blog-post-item">
                <a href="#">

                  <figure class="blog-banner-box">
                    <img src="./assets/images/blog-3.png" alt="Design digest #80" loading="lazy"/>
                  </figure>

                  <div class="blog-content">

                    <div class="blog-meta">
                      <p class="blog-category">Design</p>

                      <span class="dot"></span>

                      <time datetime="2022-02-23">Fab 23, 2022</time>
                    </div>

                    <h3 class="h3 blog-item-title">Design digest #80</h3>

                    <p class="blog-text">
                      Excepteur sint occaecat cupidatat no proident, quis nostrum exercitationem ullam corporis suscipit.
                    </p>

                  </div>

                </a>
              </li>

              <li class="blog-post-item">
                <a href="#">

                  <figure class="blog-banner-box">
                    <img src="./assets/images/blog-4.png" alt="UI interactions of the week" loading="lazy"/>
                  </figure>

                  <div class="blog-content">

                    <div class="blog-meta">
                      <p class="blog-category">Design</p>

                      <span class="dot"></span>

                      <time datetime="2022-02-23">Fab 23, 2022</time>
                    </div>

                    <h3 class="h3 blog-item-title">UI interactions of the week</h3>

                    <p class="blog-text">
                      Enim ad minim veniam, consectetur adipiscing elit, quis nostrud exercitation ullamco laboris nisi.
                    </p>

                  </div>

                </a>
              </li>

              <li class="blog-post-item">
                <a href="#">

                  <figure class="blog-banner-box">
                    <img src="./assets/images/blog-5.png" alt="The forgotten art of spacing" loading="lazy"/>
                  </figure>

                  <div class="blog-content">

                    <div class="blog-meta">
                      <p class="blog-category">Design</p>

                      <span class="dot"></span>

                      <time datetime="2022-02-23">Fab 23, 2022</time>
                    </div>

                    <h3 class="h3 blog-item-title">The forgotten art of spacing</h3>

                    <p class="blog-text">
                      Maxime placeat, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>

                  </div>

                </a>
              </li>

              <li class="blog-post-item">
                <a href="#">

                  <figure class="blog-banner-box">
                    <img src="./assets/images/blog-6.png" alt="Design digest #79" loading="lazy"/>
                  </figure>

                  <div class="blog-content">

                    <div class="blog-meta">
                      <p class="blog-category">Design</p>

                      <span class="dot"></span>

                      <time datetime="2022-02-23">Fab 23, 2022</time>
                    </div>

                    <h3 class="h3 blog-item-title">Design digest #79</h3>

                    <p class="blog-text">
                      Optio cumque nihil impedit uo minus quod maxime placeat, velit esse cillum.
                    </p>

                  </div>

                </a>
              </li>

            </ul>

          </section>

        </article>


        </div>

      </main>

      <script src="./assets/js/script.js"></script>
      <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
      <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>

    </body>

    </div>
  );
}

export default App;
