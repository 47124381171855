import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import '../App.css';
import { IonIcon } from '@ionic/react';
import { funnelOutline, eyeOutline } from 'ionicons/icons';


const Portfolio = ({ projectList }) => {
  // State variables
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredProjects, setFilteredProjects] = useState(projectList);

  // Function to extract categories
  const extractCategories = (projects) => {
    const categories = new Set();
    projects.forEach(project => {
      project.category.forEach(cat => categories.add(cat));
    });
    return Array.from(categories);
  };

  const categoriesList = extractCategories(projectList);

  // Function to handle filter selection
  const handleFilter = (category) => {
    setSelectedCategory(category);
    if (category === 'All') {
      setFilteredProjects(projectList);
    } else {
      const filtered = projectList.filter(project => project.category.includes(category));
      setFilteredProjects(filtered);
    }
  };



  return (
      <div>
        
        <header>
          <h2 class="h2 article-title">Portfolio</h2>
        </header>

        <section class="projects">

          <ul className="filter-list">

            <li className="filter-item">
              <button className={selectedCategory === 'All' ? 'active' : ''} onClick={() => handleFilter('All')}>All</button>
            </li>

            {categoriesList.map((category, index) => (
              <li className="filter-item" key={index}>
                <button className={selectedCategory === category ? 'active' : ''} onClick={() => handleFilter(category)}>{category}</button>
              </li>
            ))}

          </ul>
        


          <div className="filter-select-box">
      <button className="filter-select" data-select>
        <div className="select-value" data-select-value>
          {selectedCategory ? selectedCategory : 'Select category'}
        </div>
        <div className="select-icon">
          <IonIcon icon={funnelOutline} />
        </div>
      </button>
      <ul className="select-list">
        <li className="select-item">
          <button onClick={() => handleFilter('All')} data-select-item>
            All
          </button>
        </li>
        <li className="select-item">
          <button onClick={() => handleFilter('Web design')} data-select-item>
            Web design
          </button>
        </li>
        <li className="select-item">
          <button onClick={() => handleFilter('Applications')} data-select-item>
            Applications
          </button>
        </li>
        <li className="select-item">
          <button onClick={() => handleFilter('Web development')} data-select-item>
            Web development
          </button>
        </li>
      </ul>
    </div>

          
          <ul className="project-list">

          {filteredProjects.map((project, index) => (
              <li className="project-item active" data-filter-item data-category={project.category} key={index}>
                <Link to={`/project/${project.id}`}>
                  <figure className="project-img">
                    <div className="project-item-icon-box">
                      <IonIcon icon={eyeOutline} />
                    </div>
                    {/* <img src={project.img[0]} alt={project.title} loading="lazy" /> */}
                  </figure>
                  <h3 className="project-title">{project.title}</h3>
                  <p className="project-category">{project.category.join(' | ')}</p>
                </Link>
              </li>
            ))}

          </ul>


        </section>


      </div>
    );
  }

export default Portfolio;