import React from 'react';
import '../App.css';
import { IonIcon } from '@ionic/react';
import { documentTextOutline } from 'ionicons/icons';

import ResumeComponent from './ResumeComponent';

const Resume = ({ resumeData }) => {
  const { pdfLink, experienceList, educationList } = resumeData;

  return (
    <div>
      <header>
        <h2 className="h2 article-title">Resume</h2>

        <a href={pdfLink} target="_blank" rel="noreferrer" className="resume-btn">
          <button className="back-btn">
            <IonIcon icon={documentTextOutline} />
            View Full Resume
          </button>
        </a>
      </header>

      <br />

      <ResumeComponent componentList={experienceList.list} sectionTitle={experienceList.sectionTitle} />
      <ResumeComponent componentList={educationList.list} sectionTitle={educationList.sectionTitle} />
    </div>
  );
};

export default Resume;
