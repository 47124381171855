import React from 'react';
import '../App.css';
import { IonIcon } from '@ionic/react';
import { paperPlane } from 'ionicons/icons';
import emailjs from '@emailjs/browser';
import { Toaster, toast } from 'react-hot-toast';


const Contact  = () => {
  const form = React.useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_zl7bssm', 'template_36s94wu', form.current, {
        publicKey: 'qR4CK0idra5ezhgav',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          // clear form
          form.current.reset();
          // show success message
          toast.success("Message sent successfully !", {
            style: {
              border: '3px solid #BDD358',
              padding: '16px',
              color: '#FFFAEE',
              backgroundColor: '#00001a',
            },
            iconTheme: {
              secondary: '#FFFAEE',
              primary: '#BDD358',
            },
          });
        },
        (error) => {
          console.log('FAILED...', error.text);
          // show error message
          toast.error("Message not sent ! \nTry an other contact method", {
            style: {
              border: '3px solid #E5625E',
              padding: '16px',
              color: '#FFFAEE',
              backgroundColor: '#00001a',
            },
            iconTheme: {
              secondary: '#FFFAEE',
              primary: '#E5625E',
            },
          });
        },
      );
  };
    
        return (
            <div>

              <header>
                <h2 class="h2 article-title">Contact</h2>
              </header>

              <p class="timeline-text">You can book a meeting on Calendly or contact me directly on my Email address, Phone number or by filling the form below.</p>

              <div class="separator"></div>



              <section class="contact-form">

                <h3 class="h3 form-title">Contact Form</h3>

                <form ref={form} action="#" class="form" data-form onSubmit={sendEmail}>
        
                  <div class="input-wrapper">
                    <input type="text" name="fullname" class="form-input" placeholder="Full name" required data-form-input/>

                    <input type="email" name="email" class="form-input" placeholder="Email address" required data-form-input/>
                  </div>

                  <textarea name="message" class="form-input" placeholder="Your Message" required data-form-input></textarea>

                  <button class="form-btn" type="submit" data-form-btn value="Send">
                    <IonIcon icon={paperPlane} />
                    <span>Send Message</span> 
                  </button>

                </form>

                <div class="separator"></div>



                <section class="mapbox" data-mapbox>
                  <figure>
                    <iframe
                      src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=paris+(My%20Business%20Name)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      width="400" height="300" loading="lazy"></iframe>
                  </figure>
                </section>


              </section>


            </div>
        );
    }
export default Contact;