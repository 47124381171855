import React from 'react';
import avatar from '../assets/images/my-avatar.png';
import { IonIcon } from '@ionic/react';
import { chevronDown, mailOutline, phonePortraitOutline, locationOutline, calendarOutline, logoGithub, logoLinkedin } from 'ionicons/icons';


const Sidebar  = () => {
    
    const [showContacts, setShowContacts] = React.useState(false);

    const toggleContacts = () => {
        setShowContacts(!showContacts);
    }

    

    return (
        <div className={`sidebar ${showContacts ? 'active' : ''}`} data-sidebar>
            <div class="sidebar-info">

                <figure class="avatar-box">
                    <img src={avatar} alt="Ayman Ben hajjaj" width="80"/>  
                </figure>

                <div class="info-content">
                    <h1 class="name" title="Ayman Ben hajjaj">Ayman Ben hajjaj</h1>
                    <p class="title">Data - AI Engineer</p>
                </div>

                <button class="info_more-btn box" data-sidebar-btn onClick={toggleContacts}>
                    <span>Show Contacts</span>
                    <IonIcon icon={chevronDown} />
                </button>

            </div>

            <div class="sidebar-info_more">

                <div class="separator"></div>

                    <ul class="contacts-list">

                        <li class="contact-item">
                            <div class="icon-box">
                                <IonIcon icon={mailOutline} />
                            </div>

                            <div class="contact-info">
                                <p class="contact-title">Email</p>
                                <a href="mailto:benhajjaj.ayman@gmail.com" class="contact-link">benhajjaj.ayman@gmail.com</a>
                            </div>
                        </li>

                        <li class="contact-item">
                            <div class="icon-box">
                                <IonIcon icon={phonePortraitOutline} />
                            </div>

                            <div class="contact-info">
                                <p class="contact-title">Phone</p>
                                <a href="tel:+33662589502" class="contact-link">+33 662 589 502</a>
                            </div>
                        </li>

                        <li class="contact-item">
                            <div class="icon-box">
                                <IonIcon icon={calendarOutline} />
                            </div>

                            <div class="contact-info">
                                <p class="contact-title">Book a meeting</p>
                                <a href="https://calendly.com/benhajjaj-ayman/20min" target="_blank" class="contact-link">Calendly</a>
                            </div>
                        </li>

                        <li class="contact-item">
                            <div class="icon-box">
                                <IonIcon icon={locationOutline} />
                            </div>

                            <div class="contact-info">
                                <p class="contact-title">Location</p>
                                <address>Paris, Île-de-France, France</address>
                            </div>
                        </li>
                    </ul>

                <div class="separator"></div>

                <ul class="social-list">
                    <li class="social-item">
                        <a href="https://www.linkedin.com/in/benhajjaj/" target="_blank" class="social-link">
                            <IonIcon icon={logoLinkedin} />
                        </a>
                    </li>

                    <li class="social-item">
                        <a href="https://github.com/Senshiben-efrei/" target="_blank" class="social-link">
                            <IonIcon icon={logoGithub} />
                        </a>
                    </li>
                </ul>

            </div>
        </div>
    );
}

export default Sidebar;